import { graphql } from 'gatsby';
import * as React from 'react';
import Layout from '~/components/Layout';
import { WpPage_Page_FlexibleContent } from '~/_generated/types';

import { ArticleMasthead } from '../components/article/ArticleMasthead';
import { ArticleShareBar } from '../components/article/ArticleShareBar';
import ArticleRenderer from '../components/article/ArticleRenderer';
import { ArticleRelatedResources } from '../components/article/ArticleRelatedPosts';

import Seo from 'gatsby-plugin-wpgraphql-seo';

export default function articlePage({
  data: { wpPage, wpPost, wp },
}: {
  data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } };
}) {
  const author = wpPost?.author?.node?.userDisplayInformation?.userDisplayInformation;
  const article = wpPost?.postTypeSingle?.article;
  const categories = wpPost?.terms.nodes;

  return (
    <Layout wp={wp} darkHeader={true}>
      <Seo post={wpPost} title={wpPost?.seo?.title || wpPost?.title} />
      <ArticleMasthead
        wp={wp}
        categories={categories}
        author={author}
        date={wpPost?.date}
        content={wpPost?.postTypeSingle?.masthead}
        returnLink={'/blog/'}
        hideAuthor
      />
      <ArticleRenderer content={article?.articleBlocks} prefix={'Post_Posttypesingle_Article_ArticleBlocks_'} />
      <div className={`container mb-8 md:mb-12 ${wpPost?.postTypeSingle?.relatedPosts && 'lg:mb-[72px]'}`}>
        <div className="max-w-[50rem] mx-auto pt-8 md:pt-[40px] border-y border-borderGrey">
          <ArticleShareBar />
        </div>
      </div>
      {wpPost?.postTypeSingle?.relatedPosts ? (
        <ArticleRelatedResources
          relatedItem={wpPost?.postTypeSingle?.relatedPosts}
          relatedHeading={wpPost?.postTypeSingle?.relatedHeading}
        />
      ) : (
        <div className="h-[30px] md:h-[70px]" />
      )}
    </Layout>
  );
}

export const pageQuery = graphql`
  query Article($id: String!) {
    wpPage(id: { eq: $id }) {
      ...SEO
      ...GeneratedWpPage
    }
    wpPost(id: { eq: $id }) {
      title
      uri
      date
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          srcSet
          sourceUrl
          localFile {
            publicURL
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          srcSet
          localFile {
            publicURL
          }
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      ...GeneratedWpPost
      author {
        node {
          userDisplayInformation {
            userDisplayInformation {
              displayName
              bio
              profilePicture {
                altText
                publicUrl
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
    wp {
      ...GeneratedWp
      header {
        globalUpdatedHeader {
          fieldGroupName
          headerUpdated {
            topLevelNavigation {
              link {
                title
                target
                url
              }
              addMenu
              subMenu {
                tabs {
                  heading
                  description
                  menuType
                  columnMenu {
                    columns {
                      heading
                      cards {
                        image {
                          ...Image
                        }
                        link {
                          title
                          url
                        }
                        description
                      }
                    }
                  }
                  rowMenu {
                    heading
                    cards {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                  resourcesMenu {
                    listSubheading
                    listItems {
                      image {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                    }
                    cardsSubheading
                    cardItems {
                      thumbnail {
                        ...Image
                      }
                      link {
                        title
                        url
                      }
                      description
                    }
                  }
                }
              }
            }
            buttons {
              textLink {
                target
                title
                url
              }
              buttonLink {
                target
                title
                url
              }
            }
            socials {
              image {
                ...Image
              }
              link {
                target
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;
